import { AppConfig } from 'blockstack'

// APP

export const appConfig = new AppConfig(['store_write', 'publish_data'])

// GAIA

export const STORAGE_FILENAME = 'storage.json'
export const STORAGE_ENCRYPTION = false
// export const EXPLORER_URL = 'https://explorer.blockstack.org'
export const STORAGE_EXAMPLE = {
    "matrix": [
        {
            "name": "My Matrix",
            "headline": "I want to create a crazy product",
            "id": "MD5-FROM-TIME",
            "chapters": [
                {
                    "design": 1,
                    "product": 0
                }
            ],
            "rows": [
                {
                    "geometric": 1,
                    "transform": 0
                }
            ],
            "options": [
                {
                    "punctual": 1,
                    "top-left": 1
                }
            ]
        }
    ]
}

// MSG

export const MSG_RESET_QUESTION = 'Do you want to reset the Matrix?';