import React, { Component } from 'react';
import { Person } from 'blockstack';
import Collapsible from 'react-collapsible';

import {
    STORAGE_FILENAME,
    STORAGE_ENCRYPTION,
    STORAGE_EXAMPLE,
    MSG_RESET_QUESTION
} from './assets/constants'
import { setVisibility } from './assets/utils'

import Bot from './components/Bot';
import Chapter from './components/Chapter';
import Footer from './components/Footer';
import Nav from './components/Nav';
import Row from './components/Row';
import Notification from './components/Notification';

// import { loading } from './assets/utils' // jsonCopy, remove, check, add

// const avatarFallbackImage = '../assets/avatar-placeholder.png'
// const dataFile = 'test123.json'

export default class Matrix extends Component {

    constructor(props) {
        super(props)

        this.state = {
            // Blockstack
            person: {
                name() {
                    return 'Anonymous';
                },
                avatarUrl() {
                    return '';
                },
            },
            username: '',
            // Storage
            storage: [],
            matrixHeadline: '',
            matrixChapters: [],
            matrixTiles: '',
            // Matrix
            matrixState: '',
            // UI
            isLoading: true
        }

        this.toggleView = this.toggleView.bind(this) // Toggles matrix state
        this.checkSegment = this.checkSegment.bind(this) // Updates segemented control
        this.setVisibility = setVisibility.bind(this) // When matrix state was toggled
        this.resetStorage = this.resetStorage.bind(this) // Function
    }

    // Matrix

    toggleView(state) {
        this.setState({ matrixState: state })
        console.log('Matrix toggleView to --> ' + state);
    }

    checkSegment(btn) { // all, selected, hidden
        const segmentState
            = (this.state.matrixState === '' && btn === 'all')
                ? 'btn on'
                : (this.state.matrixState === 1 && btn === 'selected')
                    ? 'btn on'
                    : (this.state.matrixState === 0 && btn === 'hidden')
                        ? 'btn on'
                        : 'btn'
        return segmentState
    }

    // Render

    UNSAFE_componentWillMount() {
        const { userSession } = this.props;

        this.setState({
            person: new Person(userSession.loadUserData().profile),
            username: userSession.loadUserData().username,
        });

        this.loadStorage();
    }

    // Reset GAIA storage file
    resetStorage() {
        // var txt;
        var r = window.confirm(MSG_RESET_QUESTION);
        if (r === true) {
            const options = { encrypt: STORAGE_ENCRYPTION };
            this.props.userSession.putFile(STORAGE_FILENAME, JSON.stringify(STORAGE_EXAMPLE), options);
            // TODO --> Force reload
            // window.location.reload() --> async see below:
            // https://getstream.io/blog/javascript-promises-and-why-async-await-wins-the-battle/#how-do-i-start-using-async-await
        } else {
            return
        }
    }

    // Load GAIA storage file
    loadStorage() {
        const options = { decrypt: STORAGE_ENCRYPTION };
        // 🛑 RESET STORAGE @ GAIA ——————————————————————————————————————————————
        // this.resetStorage(); console.log('✅ Matrix --> GAIA RESETTED');
        this.props.userSession.getFile(STORAGE_FILENAME, options)
            .then((content) => {
                if (content) {
                    const storage = JSON.parse(content);
                    this.setState({ storage });
                    console.log('✅ Matrix --> loadStorage() ');
                    // console.log(this.state.storage.matrix);

                    // Check if JSON exists
                    if (!this.state.storage.matrix) {
                        // TODO: Reset storage, if not available
                        return null
                    } else {
                        // Read storage 
                        const matrixHeadline = this.state.storage.matrix[0].headline
                        this.setState({ matrixHeadline });
                        console.log('✅ Matrix --> matrixHeadline()');
                        // console.log(this.state.matrixHeadline);
                        const matrixChapters = this.state.storage.matrix[0].chapters[0]
                        this.setState({ matrixChapters });
                        console.log('✅ Matrix --> matrixChapters()');
                        // console.log(this.state.matrixChapters);
                        const matrixTiles = this.state.storage.matrix[0].options[0]
                        this.setState({ matrixTiles });
                        console.log('✅ Matrix --> matrixTiles()');
                        // console.log(this.state.matrixTiles);
                    }
                }
            })
    }

    // Update chapter state --> Gaia
    updateChapterState(name, state) {
        const matrixData = this.state.storage.matrix[0].chapters[0]
        console.log('✅ Matrix --> updateChapterState() ' + name)
        // console.log(matrixData)
        this.setState(prevState => {
            const storage = { ...prevState.storage };
            matrixData[name] = this.newState(state);
            console.log('📄 Status --> was=' + state + ' & new=' + this.newState(state) + ' --> ' + name);
            return { storage };
        }
            , this.saveStorage(this.state.storage, name) // Callback
        )
    }

    // Save to Gaia
    saveStorage = (storage, name) => () => {
        const options = { encrypt: STORAGE_ENCRYPTION }
        this.props.userSession.putFile(STORAGE_FILENAME, JSON.stringify(storage), options)
        console.log('✅ File --> saveStorage() --> ' + name)
    }

    // Render components
    renderData(data, userSession, storage) {

        const chapters = data.content.map((item, index) => {
            const chapterName = item.chapter.toLowerCase()
            const chapterid = 'chapter-' + chapterName;
            const matrixChapters = this.state.matrixChapters;

            return (
                <Collapsible
                    // --> https://www.npmjs.com/package/react-collapsible
                    id={chapterName}
                    open={matrixChapters.hasOwnProperty(chapterName) ? true : false}
                    classParentString={'chapter'}
                    easing={'cubic-bezier(0.23, 1, 0.32, 1)'}
                    key={index}
                    trigger={
                        <Chapter name={item.chapter} key={index} emoji={item.emoji} id={chapterid} />
                    }
                // handleTriggerClick={console.log('click…')}
                >
                    <div className="chapter-content">
                        {this.renderRows(item.rows, chapterid, userSession, storage)}
                    </div>
                </Collapsible >
                // </div>

            );
        });
        return chapters;
    }

    // Render rows
    renderRows(data, chapterid, userSession, storage) {
        // console.log(item.tiles);
        const rows = data.map((item, index) => {
            return (
                <Row
                    userSession={userSession}
                    name={item.name}
                    note={item.note}
                    content={item.tiles}
                    key={index}
                    matrixState={this.state.matrixState}
                    matrixTiles={this.state.matrixTiles}
                    setVisibility={this.setVisibility}
                    storage={storage}
                /> // dataFile={dataFile}
            );
        });
        return rows
    }

    // TODO: Add Loading state
    componentDidMount() {
        this.setState({ isLoading: false });
    }

    render() {
        // Blockstack
        const { handleSignOut, userSession } = this.props
        const { person, username, matrixHeadline, isLoading, storage } = this.state

        const logo = '../assets/morpholous-logo.svg'
        const appData = require('./data/data.json')

        // const appStorage = this.state.storage

        return (
            <div id="matrix">
            <Notification
                text={'This is a test notification with a long text.'}
                color={'black'}
                show={true}
            />
                <Nav
                    logo={logo}
                    username={username}
                    name={person.name()}
                    avatar={person.avatarUrl()}
                    userSession={userSession}
                    handleSignOut={handleSignOut}
                    matrixHeadline={matrixHeadline}
                    toggleView={this.toggleView}
                    checkSegment={this.checkSegment}
                    resetStorage={this.resetStorage}
                    isLoading={isLoading}
                />

                <content id="content">
                    {this.renderData(appData, userSession, storage)}
                </content>

                {/* {console.log('--> storage @ render:')}
        {console.log(this.state.storage)} */}

                <Bot />
                <Footer
                    username={username}
                    avatar={person.avatarUrl()}
                    handleSignOut={handleSignOut}
                />
            </div>
        );
    }
}
