import React, { Component } from 'react'
import {
    STORAGE_FILENAME,
    STORAGE_ENCRYPTION,
    // STORAGE_EXAMPLE
} from '../assets/constants'
import {
    getImage,
    getPlaceholder,
    setVisibility,
    newState
} from '../assets/utils' // jsonCopy, remove, check

// import '../styles/_global.scss'

export default class Tile extends Component {

    constructor(props) {
        super(props)

        this.state = {
            tileState: '1',
            storage: []
        }
    }

    componentDidUpdate(prevProps) {
        // Update tiles if data changed
        if (prevProps.matrixTiles !== this.props.matrixTiles) {
            this.loadState();
        }
    }

    // Get state from storage
    loadState() {

        // Write storage in state
        this.setState({ storage: this.props.storage })

        // Get state from storeage
        const tileList = this.props.matrixTiles;
        const tileName = this.props.name;

        const state
            = (tileList.hasOwnProperty(tileName) && tileList[tileName] === 1)
                ? this.setState({ tileState: 1 })
                : (tileList.hasOwnProperty(tileName) && tileList[tileName] === 0)
                    ? this.setState({ tileState: 0 })
                    : this.setState({ tileState: '' })

        return state
    }

    // Select tile
    selectTile(id, name, row) {
        if (this.state.tileState === false || this.state.tileState === '' || this.state.tileState === 0) {
            this.setState(() => ({ tileState: 1 }))
        } if (this.state.tileState === true || this.state.tileState === 1) {
            this.setState(() => ({ tileState: '' }))
        }

        this.updateOptionState(name, this.state.tileState)
        console.log('✅ File --> selectTile() --> ' + id + ' / ' + name + ' @ ' + row)
    }

    // Hide tile
    toggleTile(id, name, row) {
        this.state.tileState === 1 || this.state.tileState === ''
            ? this.setState({ tileState: 0 })
            : this.setState({ tileState: '' })

        console.log('✅ File --> hideTile() --> ' + id + ' / ' + name + ' @ ' + row)
    }

    // Update JSON
    updateOptionState(name, state) {
        const matrixData = this.state.storage.matrix[0].options[0]
        console.log('✅ File --> updateOptionState() ' + name)
        // console.log(matrixData)
        this.setState(prevState => {
            const storage = { ...prevState.storage };
            matrixData[name] = newState(state);
            console.log('📄 Status --> was=' + state + ' & new=' + newState(state) + ' --> ' + name);
            return { storage };
        }
            , this.saveStorage(this.state.storage, name) // Callback
        )
    }

    // Save to Gaia
    saveStorage = (storage, name) => () => {
        const options = { encrypt: STORAGE_ENCRYPTION }
        this.props.userSession.putFile(STORAGE_FILENAME, JSON.stringify(storage), options)
        console.log('✅ File --> saveStorage() --> ' + name)
        // console.log(this.state.storage.matrix[0].options[0])
    }

    render() {
        const { addv, name, row, verb, matrixState } = this.props;
        const id = name.replace(" ", "-");

        // this.loadState(this.props.matrixTiles);
        // console.log(this.props.matrixTiles);   

        return (
            <div
                id={id}

                className={
                    this.state.tileState === ''
                        ? setVisibility('tile', '', matrixState)
                        : this.state.tileState === 0
                            ? setVisibility('tile', 0, matrixState)
                            : this.state.tileState === 1
                                ? setVisibility('tile', 1, matrixState)
                                : 'tile'
                }

                data-verb={verb}
                data-addv={addv}
                data-option={name}
                data-option-alt={name}
            // onClick={this.selectTile.bind(this, id, name, row)}
            // onClick={this.addTask}
            >
                {/* TODO: Repair CSS of .tile-close */}
                <div className="tile-buttons">
                    <div
                        className={
                            this.state.tileState === '' || this.state.tileState === 1
                                ? 'tile-hide'
                                : 'tile-add'
                        }
                        onClick={this.toggleTile.bind(this, id, name, row)}
                    ></div>
                </div>
                <div
                    className="tile-content"
                    onClick={this.selectTile.bind(this, id, name, row)}
                >
                    <img
                        className="tile-image"
                        src={getImage(row + '-' + name)}
                        onError={getPlaceholder}
                        alt=""
                    />
                    <div className="tile-title">{name}</div>
                </div>
            </div>
        );
    }
}