import React, { Component } from 'react';

export default class Signin extends Component {

  render() {
    const { handleSignIn } = this.props;

    const logoStyle = {
      width: '24px',
      margin: '0 12px 0 0'
    };

    const canvasStyle = {
      position: 'absolute',
      zIndex: '-1'
    };

    const letterBox = {
      cursor: 'help'
    }

    return (
      // TODO: Check https://app.co/mining/apps for Microsite inspiration
      // TODO: Add Microsite layout --> see G.Bookmarks for examples
      // TODO: Add screenhot
      // TODO: Add illustrations --> https://icons8.com/vector-creator/dashboard
      // TODO: Add description for morphological matrix --> see Medium article
      // TODO: Change circle in animation to squares --> see .js file
      <div id="wrapper-header" className="wrapper">
        <div className="wrapper-inner">

          <h6 style={letterBox} className="text-primary">[— ˌmɔrf(ə)ləs —]</h6>
          <h1 className="">morpholous</h1>
          <h4>The Innovator's Boilerplate</h4>
          <br />
          <br />
          <div
            className="btn btn-lg"
            id="signin-button"
            onClick={handleSignIn.bind(this)}
          >
            <img src="../assets/blockstack-logo.svg" style={logoStyle} alt="" /> Sign In with Blockstack
          </div>

          <br />
          <br />

          <a href="https://blockstack.org/try-blockstack" target="_blank" rel="noopener noreferrer">About Blockstack</a>

        </div>
        <canvas id="wrapper-canvas" style={canvasStyle}></canvas>
      </div>

      // TODO: Add Footer
      // TODO: Add Licenses @ Footer --> https://icons8.com/license
    );
  }
}
