import React, { Component } from 'react'

export default class Notification extends Component {

    render() {

        const {
            text,
            // color,
            // show
        } = this.props //checkSegment

        return (
            <div id="notification">
                <div id="notificationText">{text}</div>
            </div>
        )
    }
}