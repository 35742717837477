// import { STORAGE_FILENAME, STORAGE_ENCRYPTION, STORAGE_EXAMPLE } from 'constants'
// These functions have been extracted out of Dashboard.js since
// they are the logical functions being tested. 

export function jsonCopy(object) {
  return JSON.parse(JSON.stringify(object))
}

// All three functions take in a state and return a list object of tasks
export function remove(index, state) {
  const tasks = jsonCopy(state.tasks)
  tasks.splice(index, 1) // remove subject at index
  return tasks
}

export function add(state) {
  const task = state.value
  const tasks = jsonCopy(state.tasks)
  tasks.push([task, false])
  return tasks
}

export function check(index, state) {
  const tasks = jsonCopy(state.tasks)
  tasks[index][1] = !tasks[index][1]
  return tasks
}

// General

// Check visibility of elements based on it's state and the matrix's toggled view
// eSetTo ==> '' = default/ 1 = shown / 0 = hidden
export function setVisibility(e, eSetTo, matrixState) {
  const visibility
    = (matrixState === 1 && eSetTo === 'x')               // If "Selected" show selected elements
      ? e + ' hidden'
      : (matrixState === 1 && eSetTo === 1)               // If "Selected" show selected elements
        ? e + ' selected'
        : (matrixState === 1 && eSetTo === '')            // If "Selected" hide default elements
          ? e + ' hidden'
          : (matrixState === 1 && eSetTo === 0)          // If "Selected" hide hidden elements
            ? e + ' hidden'
            : (matrixState === 0 && eSetTo === 1)         // If "Hidden" hide selected elements
              ? e + ' selected hidden'
              : (matrixState === 0 && eSetTo === '')      // If "Hidden" hide set elements
                ? e + ' hidden'
                : (matrixState === '' && eSetTo === 1)    // If "All Options" select selected elements
                  ? e + ' selected'
                  : (matrixState === '' && eSetTo === 0)  // If "All Options" hide hidden elements
                    ? e + ' hidden'
                    : e                                   // If "All Options" show all elements
  return visibility
}

// Tile

// Get tile image
export function getImage(name) {
  var imageName = name.toLowerCase()
  return process.env.PUBLIC_URL + '/images/' + imageName + '.png'
}

// Get placeholder image
export function getPlaceholder(ev) {
  ev.target.src = process.env.PUBLIC_URL + '/images/placeholder.png'
}

// GAIA

// // Reset GAIA storage file
// export function resetStorage() {
//   const options = { encrypt: STORAGE_ENCRYPTION };
//   this.props.userSession.putFile(STORAGE_FILENAME, JSON.stringify(STORAGE_EXAMPLE), options);
// }

// Toggle state 1-0
export function newState(state) {
  if (state === 1) {
    return ``
  } if (state === 0 || !state) {
    return 1
  }
}