import React, { Component } from 'react'

export default class Bot extends Component {
    render() {
        return (
            <div id="bot">
                <div id="mo">
                    <span role="img" aria-label="bot">
                    🧐
                    </span></div>
                <div id="moMsg">Go back to the drawing board!</div>
            </div>
        )
    }
}