import React, { Component } from 'react'
import Matrix from './Matrix.js'
import Signin from './Signin.js'
import { UserSession, AppConfig } from 'blockstack'

import './styles/_global.scss';

// Blockstack --> auth request
// const appConfig = new AppConfig()
const appConfig = new AppConfig(['store_write', 'publish_data'])
const userSession = new UserSession({ appConfig: appConfig })


// TODO: Cleanup code --> delete *commented* lines / inline export default / …

export default class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    handleSignIn(e) {
        e.preventDefault()
        userSession.redirectToSignIn()
    }

    handleSignOut(e) {
        e.preventDefault()
        userSession.signUserOut(window.location.origin)
    }

    render() {
        return (

            !userSession.isUserSignedIn() ?
                <Signin userSession={userSession} handleSignIn={this.handleSignIn} />
                : <Matrix userSession={userSession} handleSignOut={this.handleSignOut} />

        );
    }

    componentDidMount() {
        if (userSession.isSignInPending()) {
            userSession.handlePendingSignIn().then((userData) => {
                window.history.replaceState({}, document.title, "/")
                this.setState({ userData: userData })
            });
        }
    }
}
