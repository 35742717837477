import React, { Component } from 'react'
// import '../styles/_global.scss';

export default class Footer extends Component {
    render() {
        // const {
            // handleSignOut,
            // matrixHeadline,
            // isLoading,
            // toggleView,
            // checkSegment,
            // logo,
            // name,
            // username,
            // avatar
        // } = this.props //checkSegment

        return (
            <div id="footer">
                <div id="footer-menu">
                    <span className="emoji" role="img" aria-label="hamburgermenu">🍔</span>
                </div>
                <div id="footer-content">

                </div>
            </div>
        )
    }
}
