import React, { Component } from 'react'

// import '../styles/_global.scss'


export default class Nav extends Component {

  // Reset GAIA storage file
  // resetStorage(storageFile, storageExample, storageEncryption) {
  //   const options = { encrypt: storageEncryption };
  //   this.props.userSession.putFile(storageFile, JSON.stringify(storageExample), options);
  //   console.log('💥 Nav --> resetStorage() ');
  //   // console.log(' Nav --> resetStorage() ');
  // }

  render() {
    const {
      handleSignOut,
      matrixHeadline,
      isLoading,
      toggleView,
      checkSegment,
      resetStorage,
      logo,
      name,
      username,
      avatar
    } = this.props //checkSegment

    return (
      <nav className="nav">
        <div id="nav-header">
          <div id="controls-left">
            <div id="tabs">
              <div className="tab">
                <a id="logo" href="/">
                  <img id="logo-image" className={isLoading ? "loading-logo" : ""} src={logo} alt="/ˈmɑːv(ə)ləs/" />
                </a>
                {/* <div className="tab-name">morpholous</div> */}
              </div>
            </div>
          </div>

          {/* TODO: Input - Focus on load */}
          {/* TODO: Input - Change Placeholder text, if blank */}
          {/* TODO: Inout - Store on writing */}
          <input defaultValue={matrixHeadline} type="text" className="input-text" name="" id="statement" aria-describedby="helpId" placeholder="Let's create a …" />



          <div id="controls-right">

            <a className="btn btn-secondary btn-img mx-2" href={'https://explorer.blockstack.org/name/' + username + ''} target="_blank" rel="noopener noreferrer">
              <img
                src={avatar}
                className="avatar"
                alt={username}
              />
              {/* TODO: Trim '.id.blockstack' in username starting from first dot */}
              {/* {name ? (name) : (username)} */}
              {name ? (name) : (username)}
            </a>

            {/* <a href="#/" className="btn btn-secondary btn-img mx-3">
              <img
                src={avatar}
                className="avatar"
                alt=""
              />
              {username}
            </a> */}
            <div
              className="btn mx-2"
              id="signout-button"
              onClick={handleSignOut.bind(this)}
            >
              Logout
          </div>

          </div>

        </div>

        <div id="nav-controls">

          <div id="view-controls">
            {/* TODO: Low Prio - Collapse All ▲▼ */}
            {/* <div className="btn btn-secondary">▲ Collapse all</div> */}

            {/* TODO: Go to Options --> Dropdown + Scroll to section */}
            <div className="btn btn-secondary">
              <span role="img" aria-label="">🔍</span>
              Go to Options
              </div>
            {/* ▽△ */}
          </div>

          <div id="controls-center">
            <div className="segmented">
              <div
                id="show-selected"
                className={checkSegment('selected') + ' btn-primary'}
                onClick={() => toggleView(1)}
              ><span><span role="img" aria-label="Selected"></span> Selected</span></div>
              <div
                id="show-all"
                className={checkSegment('all')}
                onClick={() => toggleView('')}
              ><span>Available</span></div>
              <div
                id="show-hidden"
                className={checkSegment('hidden')}
                onClick={() => toggleView(0)}
              ><span><span role="img" aria-label="Hidden"></span> Hidden</span></div>
            </div>
          </div>

          <div id="controls-right">
            {/* TODO: Donate via BitCoin --> Add QR Code */}
            {/* TODO: Donate via PayPal --> Add Donation-URL  */}
            {/* TODO: Donate Options --> $1 Ristretto, $2 Espresso, $3 Coffee, $4 Filter, $5 Cold Brew, $6 French Press */}
            <div id="reset" onClick={resetStorage} className="btn btn-secondary mx-2">
              <span role="img" aria-label="Danger">🚨</span> Reset
            </div>
            <a id="donate" href="#donate" target="_blank" className="btn btn-secondary mx-2">
              <span role="img" aria-label="Espresso">☕️</span> Donate
            </a>
            <a id="feedback" href="#feedback" target="_blank" className="btn btn-secondary mx-2">
              <span role="img" aria-label="Email">🧐</span> Feedback
            </a>
            {/* ▲▼ */}
            {/* <div className="btn btn-secondary mx-2">🖍Design ▼</div> */}
            {/* ▽△ */}
          </div>

        </div>

      </nav >
    );
  }
}