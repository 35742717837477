import React, { Component } from 'react'

export default class Chapter extends Component {

    // constructor(props) {
    // super(props)
    // this.state = { chapterIsOpen: this.props.visibility }
    // }

    // switchVisibility() {
    //     this.props.visibility()
    //     // console.log(this.props.visibility)
    // }

    // toggleVisibility() {
    //     // this.switchClass();
    //     this.switchVisibility.bind(this)
    // }

    render() {
        // const openIcon = "▲"
        // const closedIcon = "▼"

        return (
            <div className={"chapter-header"} id={this.props.name.toLowerCase()}>
                <div className="chapter-title">
                    <span className="chapter-emoji" role="img" aria-label={this.props.name}>{this.props.emoji}</span>
                    <span className="chapter-name">{this.props.name}</span>
                    {/* <span className="chapter-icon">{this.state.chapterIsOpen ? closedIcon : openIcon}</span> */}
                </div>
            </div>
        )
    }
}
