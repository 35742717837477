import React, { Component } from 'react'
import Tile from './Tile.js';

// console.log('@Row');

export class Row extends Component {

  //   componentDidUpdate(prevProps) {
  //     // Update row if visibility changed
  //     if (prevProps.setVisibility !== this.props.setVisibility) {
  //         this.setVisibility();
  //     }
  // }

  renderTiles(row, data, userSession, dataFile, matrixTiles, matrixState, storage) {
    const tiles = data.map((item, index) => {
      return (
        <Tile
          name={item.toLowerCase()}
          row={row.toLowerCase()}
          key={index}
          userSession={userSession}
          dataFile={dataFile}
          matrixTiles={matrixTiles}
          matrixState={matrixState}
          storage={storage}
        // setVisibility={setVisibility}
        />
      );
    });
    return tiles
  }

  render() {
    const {
      userSession,
      dataFile,
      matrixTiles,
      matrixState,
      setVisibility,
      content,
      name,
      note,
      storage
    } = this.props;

    return (
      <div className="row">

        <div className={setVisibility('row-title', 'x', matrixState)}>
          {/* <div className="row-title"> */}
          <div className="row-title-placer">{name}</div>
          <div className="row-title-note">{note}</div>
        </div>

        <div className={setVisibility('row-content', 'x', matrixState)}>
          {/* <div className="row-content"> */}
          {this.renderTiles(name, content, userSession, dataFile, matrixTiles, matrixState, storage)}
          <div className="row-spacer"></div>

          <div className={setVisibility('row-end', '', matrixState)}>
            {/* <div className="row-end"> */}
            <span>◼︎</span>
          </div>

        </div>
      </div>
    )
  }
}

export default Row;
